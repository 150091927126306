import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CNCCuttingGalleryContent from "../components/GalleryContent/CNCCuttingGalleryContent"

const CNCCuttingGallery = () => (
  <Layout>
    <SEO title="Cleanse" />
    <CNCCuttingGalleryContent />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default CNCCuttingGallery
